<template>
  <div class="img-upload">
    <ul>
      <li
        v-if="!uploadText && isShowUpload"
        @click="uploadStart"
        v-loading="isUploading"
        element-loading-background="rgba(0,0,0,0.3)"
      >
        <i class="iconfont icon-a-lianhe4"></i>
        <span class="text" v-if="text">{{ text }}</span>
      </li>

      <span
        v-if="uploadText && !fileList.length"
        @click="uploadStart"
        v-loading="isUploading"
        class="upload-text"
        >{{ uploadText }}</span
      >
      <template v-if="fileList.length">
        <li v-for="(item, index) in fileList" :key="index">
          <!-- 视频 -->
          <div v-if="type === 'video'">
            <el-tooltip
              effect="dark"
              :content="item.sf_path"
              placement="top"
              :show-after="300"
            >
              <img
                class="video-img"
                src="@/assets/img/video.png"
                alt=""
                @click="videoPlay(item)"
              />
            </el-tooltip>
            <span class="del" @click="handleRemove(item)">
              <el-icon><Close /></el-icon>
            </span>
          </div>
          <div v-else>
            <!-- 图片预览 -->
            <el-image
              style="width: 100px; height: 100px"
              :src="item.file_url"
              :preview-src-list="fileList.map((obj) => obj.file_url)"
              fit="cover"
            >
            </el-image>
            <span class="del" @click="handleRemove(item)" v-if="!!noReplaceImg">
              <el-icon><Close /></el-icon>
            </span>
          </div>
        </li>
      </template>
    </ul>

    <!-- 上传组件 -->
    <el-upload
      ref="uploader"
      action=""
      :on-exceed="onExceed"
      :accept="acceptFile(type)"
      multiple
      :limit="noReplaceImg ? limit : null"
      :show-file-list="false"
      :file-list="
        fileList.map((obj) => {
          return { name: '', url: obj.file_url };
        })
      "
      :http-request="customUpload"
    >
    <template #tip v-if="type === 'filePdf'">
      <div class="el-upload__tip">
        只支持PDF格式
      </div>
    </template>
    </el-upload>
    
    <!-- 附件 -->
    <div v-if="type === 'filePdf' && annexLists.length > 0" v-for="(item, index) in annexLists" :key="index" 
      style="min-width: 150px;padding: 10px 0;display: flex;justify-content: space-between;max-width: 300px;">
      <div>{{item.name}}</div>
      <span class="del cursor-pointer" @click="handleRemove(item)" v-if="!!noReplaceImg">
        <el-icon><Close /></el-icon>
      </span>
    </div>
          
    <div class="img-tips" v-if="type === 'image' && !uploadText">
      {{ suggestText }}
    </div>
    <!-- 监控视频 弹框 -->
    <w-dialog
      ref="videoDialog"
      class="video-dialog"
      title="入口/车场名称-闸机名称"
      width="40%"
      btnWidth="140px"
      top="15vh"
      :hideFooter="true"
    >
      <video :src="videoSrc" controls="controls"></video>
    </w-dialog>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { Close } from "@element-plus/icons-vue";
// 导入七牛云上传方法
import * as qiniu from "qiniu-js";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";

const props = defineProps({
  uploadText: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "image",
  },
  limit: {
    type: Number,
    default: 1,
  },
  noReplaceImg: {
    type: Boolean,
    default: true,
  },
  uploadTitle: {
    type: String,
    default: "封面",
  },
  suggestText: {
    type: String,
    default: "建议尺寸40*40px；格式要求png，不超过2MB",
  },
  fileList: {
    type: Array,
    default() {
      return [];
    },
  },
  isShowUpload: {
    type: Boolean,
    default: true,
  },
  // 附件数据
  annexLists: {
    type: Array,
    default() {
      return [];
    },
  }
});
const emits = defineEmits(["uploadFile", "deleteFile", "uploadLoading"]);

const store = useStore();
// 七牛云上传参数
const qiniuData = computed(() => store.state.Config.qiniuData);
// 上传对象
const uploader = ref(null);
// 是否正在上传
const isUploading = ref(false);
/** 上传类型限制方法 */
const acceptFile = (type) => {
  const allowHook = {
    video: ".mp4, .ogv, .ogg, .webm",
    audio: ".wav, .mp3, .ogg, .acc, .webm, .amr",
    file: "doc,.docx,.xlsx,.xls,.pdf",
    excel: "xlsx,.xls",
    image: ".jpg, .jpeg, .png, .gif",
    filePdf: ".pdf",
  };
  if (type) {
    return allowHook[type];
  }
  let srt = null;
  for (const k in allowHook) {
    srt += allowHook[k];
  }
  return srt;
};

// 点击上传图标处理
const uploadStart = () => {
  if (!isUploading.value) {
    uploader.value.$el.children[0].children[0].click();
  }
};
/** 文件删除处理 */
const handleRemove = (obj) => {
  emits("deleteFile", obj);
};
// 自定义上传
const customUpload = (params) => {
  isUploading.value = true;
  emits("uploadLoading", true);
  const file = params.file;
  const token = qiniuData.value.token;
  const bucket = "charge";
  const date = new Date();
  const nowDay =
    date.getFullYear() + "" + (date.getMonth() + 1) + "" + date.getDate();
  const filename = file.name;
  const index = filename.lastIndexOf(".");
  const suffix = filename.substr(index);
  const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
  const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
  const key = `${bucket}/${nowDay}/${new Date().getTime()}${random}-${randoms}${suffix}`;
  const putExtra = { fname: filename };
  const config = { useCdnDomain: true, region: qiniu.region.z2 };

  const observable = qiniu.upload(file, key, token, putExtra, config);
  // 上传开始
  observable.subscribe({
    next() {},
    complete(res) {
      emits("uploadFile", {...res,filename,suffix});
      isUploading.value = false;
    },
  });
};
const onExceed = () => {
  ElMessage.warning(`只允许上传${props.limit}张${props.uploadTitle}！`);
};
/** 视频弹框 */
const videoDialog = ref(null);
/** 视频地址 */
const videoSrc = ref(null);
/** 视频播放 */
const videoPlay = (obj) => {
  videoSrc.value = obj.file_url;
  videoDialog.value.show();
};
</script>

<style lang="scss">
.img-upload {
  .img-tips {
    color: var(--text-third-color);
    line-height: 1px;
    font-size: 12px;
  }
  > ul {
    li {
      display: inline-block;
      width: 56px;
      height: 56px;
      margin-right: 8px;
      margin-bottom: 10px;
      vertical-align: middle;
      cursor: pointer;
      position: relative;

      .video-img {
        width: 56px;
        height: 56px;
      }
      .el-image {
        width: 56px !important;
        height: 56px !important;
        border-radius: 6px;
      }
      .del {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        height: 13px;
        width: 18px;
        line-height: 13px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0 4px 0 4px;
        text-align: center;
        font-size: 12px;
      }
    }

    li:first-child {
      border-radius: 3px;
      background-color: var(--search-uncheck-bg-color);
      line-height: 56px;
      text-align: center;
      position: relative;
      > i {
        color: #b3b3b3;
        font-size: 14px;
      }
      > .text {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 12px;
        color: var(--text-third-color);
      }
    }

    li:first-child:hover {
      background-color: var(--search-bg-color);
      > i {
        color: var(--theme-color);
      }
    }
    .upload-text {
      color: var(--theme-color);
      cursor: pointer;
    }
    li:hover .del {
      display: block;
    }
  }
  .el-upload {
    display: none;
  }

  .el-loading-spinner {
    top: 58%;
    .circular {
      width: 30px;
      height: 30px;
    }
  }
  .video-dialog {
    .el-dialog {
      min-width: 320px !important;
      video {
        width: 100%;
      }
    }
  }
}
</style>
